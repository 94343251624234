export const allQuestions = [
  'What do you see on the left side?',
  'What do you see on the right side?',
  'Is the any gatherins on the left side?',
  'Is the any gatherins on the right side?',
  'Are there any surveillance cameras around?',
  'Are there any shops on the left side?',
  'Are there any shops on the right side?',
  'How old could the building/space on the left be?',
  'How old could the building/space on the right be?',
  'What color is the surface on the left?',
  'What color is the surface on the right?',
  'Is there a bike lane on the left side?',
  'Is there a bike lane on the right side?',
  'What is the material under your feet?',
  'How many cars are there in the area with a radius of 10m?',
  'What history could the building on the right have?',
  'What history could the building on the left have?',
  'How does it smell around?',
  'Listen, where does the sound come from?',
  'What is the temperature of the surface on the left?',
  'What is the temperature of the surface on the right?',
  'Can you close youre eyes and follow the way with eyes being closed?',
  'Could there be a hidden garden around?'
];