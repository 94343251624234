import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import * as Icon from 'react-feather'
import './Menu.css'
import Instructions from './Instructions'


const Menu = ({ setToggle }) => {
  const [activeMenu, setActiveMenu] = useState('main');

  const CloseMenu = ({ setToggle }) => {
    const iconProps = {
      color: '#F3F1F1',
      size: 28,
      strokeWidth: 1
    }
    return (
      <div className="header">
        <button className="header-btn right" onClick={setToggle}>
          <Icon.X {...iconProps}/>
        </button>
      </div>
    )
  }

  const MenuItem = (props) => {
    return (
      <div
        className={props.title ? "menu-item title" : "menu-item"}
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
        <span className="icon-left">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </div>
    );
  }

  const LinkItem = ({ leftIcon, href, children }) => {
    return (
      <a
        className="menu-item link"
        href={href} target="_blank"
        rel="noreferrer"
      >
        <span className="icon-left">{leftIcon}</span>
        {children}
      </a>
    )
  }

  return (
    <>
      {/* <div className="menu-overlay"></div> */}

      <div className="menu-container">
        <CSSTransition
          in={activeMenu === 'main'}
          timeout={500}
          classNames="menu-primary"
          unmountOnExit>
          <div className="menu">
  
            <CloseMenu setToggle={setToggle}/>
  
            <MenuItem 
              goToMenu="insts"
              rightIcon={<Icon.ChevronRight/>}
            >
              <h3>Instructions</h3>
            </MenuItem>
  
            <MenuItem
              goToMenu="about"
              rightIcon={<Icon.ChevronRight />}
            >
              <h3>About Adrift</h3>
            </MenuItem>
  
            <MenuItem
              goToMenu="theory"
              rightIcon={<Icon.ChevronRight />}
            >
              <h3>Theoretical background</h3>
            </MenuItem>
  
            <MenuItem
              goToMenu="dev"
              rightIcon={<Icon.ChevronRight />}
            >
              <h3>Development</h3>
            </MenuItem>
  
            <MenuItem
              goToMenu="refs" 
              rightIcon={<Icon.ChevronRight />}
            >
              <h3>Links and references</h3>
            </MenuItem>
  
          </div>
        </CSSTransition>


        <CSSTransition
          in={activeMenu === 'insts'}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
        >
          <div className="menu">
            <MenuItem 
              goToMenu="main"
              leftIcon={<Icon.ChevronLeft />}
              title
            >
              <h2>Instructions</h2>
            </MenuItem>
            <Instructions />
          </div>
        </CSSTransition>
  
        <CSSTransition
          in={activeMenu === 'about'}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
        >
          <div className="menu">
            <MenuItem goToMenu="main" leftIcon={<Icon.ChevronLeft />}  title>
              <h2>Adrift</h2>
            </MenuItem>
            <div className="menu-content">
              <p>
                Adrift deconstructs Google Maps directions text** and by doing so it offers a new set of directions based on the original one, thus it enables the performers to get lost in their very own environment. "Getting lost" refers to the perception of the urban fabric in a different way than what we usually experience in <b>everyday life</b>.
              </p>
              <p>
                During the walk (or run, play, etc.) to nowhere, the performers are being asked a set of questions regarding the surrounding and offered the opportunity to record the answers in the form of conversations between them, hoping to initiate another level of observation and meaning-making through the act of walking and mapping.
              </p>
              <p>
                **<i>The directions text (generated by the Google Maps Directions API) is accessible by sharing the selected <b>walking route</b> on <b>Google Maps app for Android devices</b> (See the instructions).</i>
              </p>
            </div>
          </div>
        </CSSTransition>
  
        <CSSTransition
          in={activeMenu === 'theory'}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
        >
          <div className="menu">
            <MenuItem goToMenu="main" leftIcon={<Icon.ChevronLeft />} title>
              <h2>Theory</h2>
            </MenuItem>
            <div className="menu-content">
              <p>
                The "drift" and so the app are related to the concepts of "Psychogeography" and  and "Dérive".
              </p>
              <p className="quote">
                <b>Psychogeography</b> is an exploration of urban environments that emphasizes playfulness and "drifting". It has links to the Letterist and <b>Situationist International</b>s, revolutionary groups influenced by Marxist and anarchist theory, as well as by the attitudes and methods of Dadaists and Surrealists.[1] Psychogeography was defined in 1955 by Guy Debord as "the study of the precise laws and specific effects of the geographical environment, consciously organized or not, on the emotions and behavior of individuals."[2]
              </p>
              <p className="quote">
                <b>Dérive</b> (French: [de.ʁiv], "drift") is a revolutionary strategy originally put forward in the "Theory of the Dérive" (1956) by Guy Debord.[1] Debord defines the dérive as "a mode of experimental behavior linked to the conditions of urban society: a technique of rapid passage through varied ambiances."[2] It is an unplanned journey through a landscape, usually urban, in which participants drop their everyday relations and "let themselves be drawn by the attractions of the terrain and the encounters they find there". Though solo dérives are possible, Debord indicates "that the most fruitful numerical arrangement consists of several small groups of two or three people who have reached the same level of awareness, since cross-checking these different groups' impressions makes it possible to arrive at more objective conclusions."[3]
              </p>
            </div>
          </div>
        </CSSTransition>
  
        <CSSTransition
          in={activeMenu === 'dev'}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
        >
          <div className="menu">
            <MenuItem goToMenu="main" leftIcon={<Icon.ChevronLeft />} title>
              <h2>Development</h2>
            </MenuItem>
            <div className="menu-content">
              <p>
                Adrift is a progressive web application (PWA) which is operated by web browsers.
              </p>
              <p>
                As Google Cloud requires payment methods to grant MAPS API Key (and services that provide search, directions, etc.) it is more liberating to use Adrift on devices with <b>already installed Google Maps app to attain the directions text from</b> (Android devices).
              </p>
              <p className="quote">
                Unfortunately, Google Maps app on IOS does not share the directions text! However, other parts of Adrift works with IOS browsers, especially IOS 14.3+ which support MediaStream Recording API by default.
              </p>
              <p>
                The application uses <i>client-side storage</i> to store data only on local devices and not on a cloud service.
              </p>
              <p>
                For more information visit the source code repository accessible from the Links menu.
              </p>
            </div>
          </div>
        </CSSTransition>
  
        <CSSTransition
          in={activeMenu === 'refs'}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
        >
          <div className="menu">
            <MenuItem goToMenu="main" leftIcon={<Icon.ChevronLeft />} title>
              <h2>Links</h2>
            </MenuItem>
            <LinkItem leftIcon={<Icon.GitHub />} href="https://github.com/farzadgo/adrift-react">
              <h3>Source code (Development info)</h3>
            </LinkItem>
            <LinkItem leftIcon={<Icon.ExternalLink />} href="https://thedynamicarchive.net/component/how-to-get-lost-in-the-neighborhood">
              <h3>Methodology (The Dynamic Archive)</h3>
            </LinkItem>
            <LinkItem leftIcon={<Icon.ExternalLink />} href="http://digitalmedia-bremen.de/">
              <h3>Digital Media Bremen</h3>
            </LinkItem>
            <LinkItem leftIcon={<Icon.ExternalLink />} href="https://www.hfk2020.de/">
              <h3>University of the Arts Bremen</h3>
            </LinkItem>
            <LinkItem leftIcon={<Icon.File />} href="http://tbook.constantvzw.org/wp-content/derivedebord.pdf">
              <h3>Theory of the Dérive (PDF)</h3>
            </LinkItem>
            <LinkItem leftIcon={<Icon.BookOpen />} href="https://en.wikipedia.org/wiki/D%C3%A9rive">
              <h3>Dérive (Wiki)</h3>
            </LinkItem>
            <LinkItem leftIcon={<Icon.BookOpen />} href="https://en.wikipedia.org/wiki/Psychogeography">
              <h3>Psychogeography (Wiki)</h3>
            </LinkItem>
            <LinkItem leftIcon={<Icon.ExternalLink />} href="https://www.cddc.vt.edu/sionline/">
              <h3>Situationist International Online</h3>
            </LinkItem>
          </div>
        </CSSTransition>
      </div>

    </>
  )
}

export default Menu
